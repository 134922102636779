import {
  Card,
  Spacer,
  TextSubtitle,
  TextBody,
  Avatar,
} from '@pypestream/design-system';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { FC } from 'react';

import { ManagerContext } from '../../xstate/manager.xstate';

export interface ProjectCardProps {
  project: NonNullable<ManagerContext['projects']>[number];
  url: string;
}

export const ProjectCard: FC<ProjectCardProps> = ({ project, url }) => {
  return (
    <Card size="large" shadow="none" border="default" interactive href={url}>
      <Avatar
        size="large"
        style={{
          filter: 'grayscale(100%)',
        }}
        src={project?.pictureFile?.url}
        label={project?.name}
      />

      <Spacer size="2xsmall" />
      <TextSubtitle size="small">{project.name}</TextSubtitle>
      <Spacer size="2xsmall" />
      <TextBody size="small" variant="secondary">
        {formatDistanceToNow(parseISO(project.updatedAt), {
          addSuffix: true,
        })}
      </TextBody>
    </Card>
  );
};
