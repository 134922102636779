/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  send,
  EventObject,
  DoneInvokeEvent,
  Action,
  InvokeConfig,
  ActionMeta,
  ActionObject,
  State,
  Typestate,
  TransitionConfig,
  SingleOrArray,
  StateSchema,
  Interpreter,
  ActorRef,
  TypegenDisabled,
} from 'xstate';
import { CombinedError } from '@pypestream/api-services/urql';
import { assign } from '@xstate/immer';
import deepEqual from 'deep-equal';
import type { Draft } from 'immer';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

// Borrowed from https://github.com/reduxjs/react-redux/blob/a9235530f4799dd4b2acb3cc65e9caf32efbc44b/src/utils/shallowEqual.js
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function is(x: any, y: any): boolean {
  if (x === y) {
    return x !== 0 || y !== 0 || 1 / x === 1 / y;
  }
  // eslint-disable-next-line no-self-compare
  return x !== x && y !== y;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function shallowEqual(objA: any, objB: any): boolean {
  if (is(objA, objB)) return true;

  if (
    typeof objA !== 'object' ||
    objA === null ||
    typeof objB !== 'object' ||
    objB === null
  ) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) return false;

  for (let i = 0; i < keysA.length; i++) {
    if (
      !Object.prototype.hasOwnProperty.call(objB, keysA[i]) ||
      !is(objA[keysA[i]], objB[keysA[i]])
    ) {
      return false;
    }
  }

  return true;
}

export function isEventType<TE extends EventObject, TType extends TE['type']>(
  event: TE,
  eventType: TType
): event is TE & { type: TType } {
  return event.type === eventType;
}

export function assertEventType<
  TE extends EventObject,
  TType extends TE['type'],
>(event: TE, eventType: TType): asserts event is TE & { type: TType } {
  if (!isEventType(event, eventType)) {
    throw new Error(
      `Invalid event: expected "${eventType}", got "${event.type}"`
    );
  }
}

/**
 * For creating a React Redux like `useSelector()` hook for Xstate Context
 */
export interface TypedUseSelectorHook<MachineContext> {
  <TSelected>(
    selector: (context: MachineContext) => TSelected,
    opt?: {
      /** If `true` then deepEqual will be used to determine if React should re-render, otherwise shallowEqual is used */
      isEqualityDeep?: boolean;
    }
  ): TSelected;
}

/**
 * Use Isomorphic Layout Effect
 * Borrowed from `react-redux` for use in creating our own `useSelector` https://github.com/reduxjs/react-redux/blob/a9235530f4/src/utils/useIsomorphicLayoutEffect.js
 * React currently throws a warning when using useLayoutEffect on the server.
 * To get around it, we can conditionally useEffect on the server (no-op) and
 * useLayoutEffect in the browser. We need useLayoutEffect to ensure the store
 * subscription callback always has the selector from the latest render commit
 * available, otherwise a store update may happen between render and the effect,
 * which may cause missed updates; we also must ensure the store subscription
 * is created synchronously, otherwise a store update may occur before the
 * subscription is created and an inconsistent state may be observed
 */
export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
    ? useLayoutEffect
    : useEffect;

export function getXstateUtils<
  MachineContext,
  MachineEvents extends EventObject,
  MachineTypestates extends Typestate<MachineContext>,
  MachineStateSchema extends StateSchema,
  // MachineEventsSent extends EventObject = AnyEventObject
>(): {
  createInvokablePromise: typeof createInvokablePromise;
  createAction: typeof createAction;
  createXstateHooks: typeof createXstateHooks;
} {
  type MyActionObject<Options> = ActionObject<MachineContext, MachineEvents> &
    Options;

  type SiteAction<Options> = {
    id: string;
    exec: (
      ctx: MachineContext,
      event: MachineEvents,
      meta: ActionMeta<MachineContext, MachineEvents> & {
        action: ActionObject<MachineContext, MachineEvents> & Options;
      }
    ) => void;
    create: (opt: Options) => {
      type: string;
    } & Options;
    isAction: (
      action:
        | MyActionObject<Options>
        | ActionObject<MachineContext, MachineEvents>
    ) => action is MyActionObject<Options>;
  };

  function createAction<Options>({
    id,
    exec,
  }: {
    id: string;
    exec: (opt: {
      ctx: MachineContext;
      event: MachineEvents;
      action: MyActionObject<Options>;
      state: State<MachineContext, MachineEvents>;
    }) => void;
  }): SiteAction<Options> {
    const isAction: SiteAction<Options>['isAction'] = (
      action
    ): action is MyActionObject<Options> => action.type === id;
    return {
      id,
      create: (opt) => ({ ...opt, type: id }),
      isAction,
      exec: (ctx, event, meta) => {
        if (!isAction(meta.action)) return;
        const { action, state } = meta;
        exec({ ctx, event, action, state });
      },
    };
  }

  function createInvokablePromise<Data, RejectError = CombinedError>({
    id,
    src,
    onDone,
    onDoneTarget,
    onDoneActions = [],
    onErrorActions = [],
    onDoneSendActions,
    onErrorSendActions,
    onDoneAssignContext,
    onErrorAssignContext,
    onErrorTarget,
  }: {
    id?: string;
    src: (ctx: MachineContext, event: MachineEvents) => Promise<Data>;
    onDone?: SingleOrArray<
      TransitionConfig<MachineContext, DoneInvokeEvent<Data>>
    >;
    onDoneTarget?: string; // MachineTypestates['value']; // @todo figure out how to be top level (TopStateValues) or string
    onErrorTarget?: string;
    onDoneAssignContext?: (opt: {
      ctx: Draft<MachineContext>;
      data: Data;
    }) => void;
    onErrorAssignContext?: (opt: {
      ctx: Draft<MachineContext>;
      error: RejectError;
    }) => void;
    onDoneActions?: Action<MachineContext, DoneInvokeEvent<Data>>[];
    onDoneSendActions?: Array<
      | MachineEvents
      | ((ctx: MachineContext, event: DoneInvokeEvent<Data>) => MachineEvents)
    >;
    onErrorActions?: Action<MachineContext, DoneInvokeEvent<RejectError>>[];
    onErrorSendActions?: Array<
      | MachineEvents
      | ((
          ctx: MachineContext,
          event: DoneInvokeEvent<RejectError>
        ) => MachineEvents)
    >;
  }): InvokeConfig<MachineContext, MachineEvents> {
    if (onDone && onDoneTarget) {
      throw new Error(
        'Cannot use "createInvokablePromise" with "onDone" and "onDoneTarget". Pick one.'
      );
    }
    if (onDoneAssignContext) {
      onDoneActions.push(
        assign<MachineContext, DoneInvokeEvent<Data>>((ctx, event) => {
          onDoneAssignContext({
            ctx,
            data: event.data,
          });
        })
      );
    }

    if (onErrorAssignContext) {
      onErrorActions.push(
        assign<MachineContext, DoneInvokeEvent<RejectError>>((ctx, event) => {
          onErrorAssignContext({
            ctx,
            error: event.data,
          });
        })
      );
    }

    if (onDoneSendActions) {
      onDoneSendActions.forEach((onDoneSendAction) => {
        onDoneActions.push(send(onDoneSendAction));
      });
    }

    if (onErrorSendActions) {
      onErrorSendActions.forEach((onErrorSendAction) => {
        onErrorActions.push(send(onErrorSendAction));
      });
    }

    const result: ReturnType<typeof createInvokablePromise> = {
      src,
      onDone: onDone ?? {
        target: onDoneTarget,
        actions: onDoneActions,
      },
      onError: {
        target: onErrorTarget,
        actions: onErrorActions,
      },
    };
    if (id) result.id = id;

    return result;
  }

  /** Use after it's running */
  function createXstateHooks(
    service: Interpreter<
      MachineContext,
      MachineStateSchema,
      MachineEvents,
      MachineTypestates
    >
  ): {
    useStateMatches: typeof useStateMatches;
    useCtxSelector: typeof useCtxSelector;
    useOnEvent: typeof useOnEvent;
    useIsEventAllowed: typeof useIsEventAllowed;
    waitForEvents: typeof waitForEvents;
    useStateMatchesOneOf: typeof useStateMatchesOneOf;
    useStateMatchesAllOf: typeof useStateMatchesAllOf;
    useCurrentState: typeof useCurrentState;
  } {
    function useStateMatches<TSV extends MachineTypestates['value']>(
      stateToMatch: TSV
    ): boolean {
      const latestResult = useRef(service.state.matches(stateToMatch));
      const [matches, setMatches] = useState(latestResult.current);

      useIsomorphicLayoutEffect(() => {
        const { unsubscribe } = service.subscribe((state) => {
          const nextResult = state.matches(stateToMatch);
          if (latestResult.current === nextResult) return;
          latestResult.current = nextResult;
          setMatches(nextResult);
        });
        return unsubscribe;
      }, [stateToMatch]);

      return matches;
    }

    /**
     * Return which of multiple states are matched, or `false` if none are matched
     * @example
     * // `status` would be `'user.unknown' | 'user.loggedIn' | 'user.loggedOut' | false`
     * const status = useStateMatchesOneOf(['user.unknown', 'user.loggedIn', 'user.loggedOut']);
     */
    function useStateMatchesOneOf<
      TSV extends MachineTypestates['value'],
      MatchingState extends TSV,
    >(matchingStates: MatchingState[]): MatchingState | false {
      const latestMatchingStates = useRef(matchingStates);
      const latestResult = useRef<MatchingState | false>(
        matchingStates.find(service.state.matches) || false
      );
      const [matches, setMatches] = useState<MatchingState | false>(
        latestResult.current
      );

      // Handle when `matchingStates` changes between renders.
      // This happens nearly every time because "changes" means reference not deep equality - i.e. `['a'] !== ['a']`
      useIsomorphicLayoutEffect(() => {
        latestMatchingStates.current = matchingStates;
      });

      useIsomorphicLayoutEffect(() => {
        const { unsubscribe } = service.subscribe((state) => {
          const matchingState =
            latestMatchingStates.current.find(state.matches) || false;
          if (latestResult.current === matchingState) return;
          latestResult.current = matchingState;
          setMatches(matchingState);
        });
        return unsubscribe;
      }, []);
      return matches;
    }

    /**
     * Returns true when all states match, false when there isn't a perfect match
     * @example
     * // `isReady` would be true when logged in + products loaded.
     * const isReady = useStateMatchesAllOf(['user.loggedIn', 'products.loaded']);
     */
    function useStateMatchesAllOf<
      TSV extends MachineTypestates['value'],
      MatchingState extends TSV,
    >(matchingStates: MatchingState[]): true | false {
      const latestMatchingStates = useRef(matchingStates);
      const latestResult = useRef<true | false>(
        matchingStates.every((v) => service.state.matches(v))
      );
      const [matches, setMatches] = useState<boolean>(latestResult.current);

      // Handle when `matchingStates` changes between renders.
      // This happens nearly every time because "changes" means reference not deep equality - i.e. `['a'] !== ['a']`
      useIsomorphicLayoutEffect(() => {
        latestMatchingStates.current = matchingStates;
      });

      useIsomorphicLayoutEffect(() => {
        const { unsubscribe } = service.subscribe((state) => {
          const matchingState =
            latestMatchingStates.current.every((v) => state.matches(v)) ||
            false;
          // latestMatchingStates.current.find(state.matches) || false;
          if (latestResult.current === matchingState) return;
          latestResult.current = matchingState;
          setMatches(matchingState);
        });
        return unsubscribe;
      }, []);
      return matches;
    }

    function waitForEvents({
      events,
      eventToSend,
      timeout = 10000,
    }: {
      events: MachineEvents['type'][];
      eventToSend?: MachineEvents;
      timeout?: number;
    }): Promise<MachineEvents> {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line prefer-const
        let timeoutId: NodeJS.Timeout;
        const { unsubscribe } = service.subscribe((state) => {
          if (events.includes(state.event.type)) {
            clearTimeout(timeoutId);
            unsubscribe();
            resolve(state.event);
          }
        });
        if (eventToSend?.type) {
          service.send(eventToSend);
        }
        timeoutId = setTimeout(() => {
          unsubscribe();
          reject(
            new Error(`Waiting for event "${eventToSend?.type}" took too long`)
          );
        }, timeout);
      });
    }

    /**
     * Like Redux's `useSelector()` hook, but for Xstate Context
     * @param {selector} A function that selects data out of AppContext
     * @see https://github.com/reduxjs/react-redux/blob/a9235530f4799dd4b2acb3cc65e9caf32efbc44b/src/hooks/useSelector.js
     * @example
     * const siteId = useAppCtxSelector((ctx) => ctx.site?.siteId);
     */
    const useCtxSelector: TypedUseSelectorHook<MachineContext> = (
      selector,
      { isEqualityDeep = false } = {}
    ) => {
      const latestSelector = useRef(selector);
      const latestResult = useRef<ReturnType<typeof selector>>(
        selector(service.state.context)
      );
      const [result, setResult] = useState(latestResult.current);

      // Handle if `selector` changes between renders.
      // See https://github.com/reduxjs/react-redux/blob/a9235530f4799dd4b2acb3cc65e9caf32efbc44b/src/hooks/useSelector.js
      // Here's one possible scenario that could happen:
      // const siteId = useAppCtxSelector((ctx) => ctx.site?.siteId);
      // const roleId = useAppCtxSelector((ctx) => ctx.user?.getSiteRole(siteId));
      useIsomorphicLayoutEffect(() => {
        latestSelector.current = selector;
      });

      useIsomorphicLayoutEffect(() => {
        const { unsubscribe } = service.subscribe((state) => {
          const nextResult = latestSelector.current(state.context);
          if (nextResult === latestResult.current) return;
          const isSame = isEqualityDeep
            ? deepEqual(nextResult, latestResult.current)
            : shallowEqual(nextResult, latestResult.current);
          // console.log({ isSame, lastTime, nextResult });
          if (isSame) return;
          latestResult.current = nextResult;
          setResult(nextResult);
        });
        return unsubscribe;
      }, [isEqualityDeep]);

      return result;
    };

    /**
     * @example
     * useAppOnEvent({
     *   type: 'user.infoChanged',
     *   onEvent(event) {
     *     const { profilePic } = event.info;
     *   },
     * });
     */
    function useOnEvent<TheEventType extends MachineEvents['type']>({
      type,
      onEvent,
    }: {
      type: TheEventType;
      onEvent: (event: Extract<MachineEvents, { type: TheEventType }>) => void;
    }): void {
      useIsomorphicLayoutEffect(() => {
        const { unsubscribe } = service.subscribe(({ event }) => {
          if (event.type !== type) return;
          onEvent(event as Extract<MachineEvents, { type: TheEventType }>);
        });
        return unsubscribe;
      }, []);
    }

    /**
     * Find if an event can be sent in (i.e. will trigger a transition as long as guards pass)
     * @example
     * const isPreviewOnAllowed = useIsEventAllowed('site.previewOn');
     */
    const useIsEventAllowed = (eventType: MachineEvents['type']): boolean => {
      const [isAllowed, setIsAllowed] = useState(false);
      const latestResult = useRef(false);

      useIsomorphicLayoutEffect(() => {
        let timeoutId: NodeJS.Timeout;
        const { unsubscribe } = service.subscribe(({ nextEvents }) => {
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => {
            const isLatest = nextEvents.includes(eventType);
            if (isLatest === latestResult.current) return;
            latestResult.current = isLatest;
            setIsAllowed(isLatest);
          }, 50);
        });
        return () => {
          unsubscribe();
          clearTimeout(timeoutId);
        };
      }, [eventType]);

      return isAllowed;
    };

    const useCurrentState = () => {
      const [currentState, setCurrentState] = useState<
        State<
          MachineContext,
          MachineEvents,
          MachineStateSchema,
          MachineTypestates,
          TypegenDisabled
        >
      >(service.initialState);

      useIsomorphicLayoutEffect(() => {
        service.subscribe((state) => setCurrentState(state));
      }, []);

      return currentState;
    };

    return {
      useStateMatches,
      useStateMatchesOneOf,
      useStateMatchesAllOf,
      useCtxSelector,
      useOnEvent,
      useIsEventAllowed,
      waitForEvents,
      useCurrentState,
    };
  }

  return {
    createInvokablePromise,
    createAction,
    createXstateHooks,
  };
}

export function canSendEvent({
  state,
  event,
}: {
  event: EventObject;
  state: State<any, any>;
}): boolean {
  return state.nextEvents.includes(event.type);
}

type Subpath<T, Key extends keyof T = keyof T> = T[Key] extends Record<
  'states',
  any
>
  ? `${Key & string}.${MachineStateSchemaPaths<
      T[Key]['states'], // need to skip `states` object
      Exclude<keyof T[Key]['states'], keyof any[]>
    >}`
  : // Path<T[Key]['states'], Exclude<keyof T[Key], keyof any[]>> :
    T[Key] extends Record<string, any>
    ? `${Key & string}.${MachineStateSchemaPaths<
        T[Key],
        Exclude<keyof T[Key], keyof any[]>
      >}`
    : never;

/**
 * Turns a whole xstate schema into `thing.substate` types great for `state.matches('thing.substate')`
 * Huge thanks to https://github.com/ghoullier/awesome-template-literal-types
 * https://dev.to/nroboto/comment/1a8ao
 */
export type MachineStateSchemaPaths<
  MachineStateSchema extends Record<string, any>,
  Key extends keyof MachineStateSchema = keyof MachineStateSchema,
> = Key extends string ? Key | Subpath<MachineStateSchema, Key> : never;

export function checkIfServiceRunning(
  service: Interpreter<any> | ActorRef<any, any>
): boolean {
  return (
    !!service &&
    'machine' in service &&
    service.status === 1 &&
    service.initialized
  );
}

/**
 * The placeholder action is the minimum needed for an event for it to be registered. This is sometimes used for future potentially useful events, or if elsewhere there is something listening to those events (like the design tokens slice currently)
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const placeholderAction = (): void => {};
