/* eslint-disable react/prop-types */
import {
  AppletInstance,
  ProjectReleaseChannelConfig,
} from '@pypestream/api-services';
import { CreateAppletInstanceMutationVariables } from '@pypestream/api-services/urql';
import {
  Input,
  InputProps,
  Textarea,
  TextareaProps,
} from '@pypestream/design-system';
import { FC, ForwardedRef, forwardRef, useState } from 'react';
import { UseFormReturn, useController } from 'react-hook-form';
import { ProductCTA } from '../product-cta';
import { Product } from '../../utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ReturnAppletFormType = UseFormReturn<any, unknown, undefined>;

export type FormAppletInfoType = Pick<
  AppletInstance,
  'name' | 'description' | 'projectReleaseChannelConfigId'
>;

export type FormAppletToolingType = Pick<AppletInstance, 'appletVersionId'>;

export type FormAppletReleaseChannelsType = {
  projectReleaseChannelConfigs: ProjectReleaseChannelConfig[];
};

export type FormAppletStateType = FormAppletInfoType & FormAppletToolingType;

export type OutputAppletFormType = CreateAppletInstanceMutationVariables;

export const AppletReleaseChannels = {
  Production: 'Production',
  Testing: 'Testing',
  Development: 'Development',
};

export class AppletFormFields {
  public AppletNameField = forwardRef(
    (
      props: InputProps & { control: ReturnAppletFormType['control'] },
      ref: ForwardedRef<InputProps>
    ) => {
      const { field: nameField, fieldState: nameFiledState } = useController({
        name: 'name',
        control: props.control || undefined,
        rules: { required: 'This is a required field.' },
      });

      return (
        <Input
          ref={ref}
          placeholder="Applet name"
          type="text"
          autocomplete="off"
          value={nameField.value}
          helpText={nameFiledState.error?.message}
          hasError={nameFiledState.invalid}
          name={nameField.name}
          onChange={nameField.onChange}
          {...props}
        />
      );
    }
  );

  public AppletDescriptionField = forwardRef(
    (props: TextareaProps, ref: ForwardedRef<TextareaProps>) => (
      <Textarea
        ref={ref}
        placeholder="Description (optional)"
        autocomplete="off"
        {...props}
      />
    )
  );

  public AppletToolField: FC<{
    logo: Product['logo'];
    label: string;
    productId: string;
    confirmRemove?: boolean;
    form: ReturnAppletFormType;
  }> = ({ form, logo, label, productId, confirmRemove = false }) => {
    const { field } = useController({
      name: 'appletVersionId',
      control: form.control,
      rules: { validate: (value) => !!value?.length },
    });

    const onInputChange = (checked: boolean) => {
      if (checked) {
        field.onChange(productId);
      } else {
        field.onChange(null);
      }
    };

    const fieldIsChecked = !!field.value?.includes(productId);
    const eventHandler = { onInputChange };
    return (
      <ProductCTA
        logo={logo}
        inputName={field.name}
        inputValue={productId}
        disabled={false}
        name={label}
        checked={fieldIsChecked}
        {...eventHandler}
      />
    );
  };
}
