import { Params } from 'react-router-dom';
import { sendManagerEvent, managerService } from '../xstate/app.xstate';

export const syncXstateWithRouteParams = ({
  params,
}: {
  params: Params<string>;
  searchQuery?: URLSearchParams;
}) => {
  const { org_id: newOrgId, project_id: newProjectId } = params;

  const managerContext = managerService.getSnapshot().context;

  const { orgId, projectId } = {
    orgId: managerContext.selectedOrgId,
    projectId: managerContext.selectedProject,
  };

  if (newOrgId !== orgId) {
    sendManagerEvent({
      type: 'manager.selectOrgId',
      orgId: newOrgId,
    });
  }

  if (newProjectId !== projectId) {
    sendManagerEvent({
      type: 'manager.selectProject',
      id: newProjectId,
    });
  }
};
