import { GridCell, Modal, TextTitle, Grid } from '@pypestream/design-system';
import { FC } from 'react';

import { useManagerCtxSelector } from '../../../xstate/app.xstate';
import { ManagerContext } from '../../../xstate/manager.xstate';
import { ProjectCard } from '../../project-card';
import { Product } from '../../../utils';

interface GridItemProps {
  project: NonNullable<ManagerContext['projects']>[number];
  productId?: string;
}

const GridItem: FC<GridItemProps> = ({
  project,
  productId: selectedProductId,
}) => {
  const selectedProjectURL = project.conditionalProducts?.find(
    ({ productId }) => productId === selectedProductId
  )?.url;

  return selectedProjectURL ? (
    <GridCell xsmall="12" medium="6">
      <ProjectCard project={project} url={selectedProjectURL} />
    </GridCell>
  ) : null;
};

interface ToolModalProps {
  open: boolean;
  tools: Product[];
  selectedTool?: string;
  onClose: () => void;
}

export const ToolModal: FC<ToolModalProps> = ({
  open,
  tools,
  selectedTool,
  onClose,
}) => {
  const { projects } = useManagerCtxSelector((ctx) => ({
    projects: ctx.projects,
  }));

  const toolProjects =
    projects?.filter((p) => {
      return p?.projectProductSettings?.some(
        (s) => s.productId === String(selectedTool)
      );
    }) || [];

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={
        {
          '--ps-modal-max-width': 'min(600px, 95vw)',
          '--ps-modal-min-width': '100%',
        } as React.CSSProperties
      }
    >
      <TextTitle slot="header" size="small" textAlign="center">
        Select a project to view in{' '}
        {tools.find((p) => p.productId === selectedTool)?.label}
      </TextTitle>

      <Grid alignItems="stretch" gutter="large" rowGutter="large">
        {toolProjects.map((item, index) => (
          <GridItem key={index} project={item} productId={selectedTool} />
        ))}
      </Grid>
    </Modal>
  );
};
