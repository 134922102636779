// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import pages from '~react-pages';

import { ReactNode } from 'react';
import { RouteObject } from 'react-router-dom';
import { i18n } from '@pypestream/translations';

import { ProjectDetailsTabs } from '../pages/projects/project-details/project-details';

const updateRouteWithTheProps = <T extends Record<string, unknown>>(
  route: RouteObject,
  customProps: T
) => {
  const { element, ...rest } = route;

  return typeof element === 'object'
    ? {
        ...rest,
        element: {
          ...element,
          props: {
            ...(element as ReactNode & { props: T }).props,
            ...customProps,
          },
        },
      }
    : route;
};

export const reactPages: RouteObject[] = pages.map((route: RouteObject) => {
  if (route.path === 'organization/:org_id/projects') {
    return updateRouteWithTheProps(route, {
      title: i18n.t('manager/projects:projectsList.projects', {
        defaultValue: 'Projects',
      }),
    });
  }

  if (route.path === 'organization/:org_id/projects/:project_id') {
    return updateRouteWithTheProps(route, {
      title: i18n.t('manager/projects:sidebar.projectDetails', {
        defaultValue: 'Project Details',
      }),
      background: 'secondary',
      hasSidebar: true,
      tab: ProjectDetailsTabs.general,
    });
  }

  if (
    route.path === 'organization/:org_id/projects/:project_id/release-channels'
  ) {
    return updateRouteWithTheProps(route, {
      title: i18n.t('manager/projects:sidebar.projectDetails', {
        defaultValue: 'Project Details',
      }),
      background: 'secondary',
      hasSidebar: true,
      tab: ProjectDetailsTabs.releaseChannels,
      hideDeleteButton: true,
    });
  }

  if (
    route.path ===
    'organization/:org_id/projects/:project_id/release-channels/:release_channel_id/applets/:applet_id'
  ) {
    return updateRouteWithTheProps(route, {
      title: i18n.t('manager/projects:sidebar.appletDetails', {
        defaultValue: 'Applet Details',
      }),
      background: 'secondary',
      hasSidebar: true,
      tab: ProjectDetailsTabs.releaseChannels,
      hideDeleteButton: true,
    });
  }

  if (route.path === 'organization/:org_id/my-account') {
    return updateRouteWithTheProps(route, {
      title: 'My Account',
    });
  }

  if (route.path === 'organization/:org_id/super-admin') {
    return updateRouteWithTheProps(route, {
      title: 'Super Admin',
    });
  }

  if (route.path === 'organization/:org_id/users') {
    return updateRouteWithTheProps(route, {
      title: i18n.t('manager/users:usersList.users', {
        defaultValue: 'Users',
      }),
    });
  }

  if (route.path === 'organization/:org_id/users/:user_id') {
    return updateRouteWithTheProps(route, {
      title: i18n.t('manager/users:userDetails.title', {
        defaultValue: 'User Details',
      }),
    });
  }

  if (route.path === 'organization/:org_id/teams') {
    return updateRouteWithTheProps(route, {
      title: i18n.t('manager/teams:teamsList.teams', {
        defaultValue: 'Teams',
      }),
    });
  }

  if (route.path === 'organization/:org_id/teams/:team_id') {
    return updateRouteWithTheProps(route, {
      title: i18n.t('manager/teams:teamDetails.title', {
        defaultValue: 'Team Details',
      }),
    });
  }

  return route;
});
