import { ToastContainer, Page, PageProps } from '@pypestream/design-system';
import { OnboardingModal } from '@pypestream/ui-patterns';
import * as Sentry from '@sentry/react';
import {
  createBrowserRouter,
  RouterProvider as ReactRouterProvider,
} from 'react-router-dom';
import { FC, PropsWithChildren } from 'react';

import { PrimaryNav } from './components';
import {
  sendUserEvent,
  sendManagerEvent,
  useManagerCtxSelector,
} from './xstate/app.xstate';
import { ErrorBoundary } from './components/error-boundaries/error-boundary';
import { reactPages } from './render-routes';
import './index.scss';

export const Root: FC<PropsWithChildren> = ({ children }) => {
  const { orgId } = useManagerCtxSelector((ctx) => ({
    orgId: ctx.selectedOrgId,
  }));
  const onSuccess = () => {
    sendUserEvent({ type: 'user.refetch' });
    sendManagerEvent({ type: 'manager.refetchUserSettings', orgId });
  };

  return (
    <ErrorBoundary>
      {children}
      <ToastContainer />
      <OnboardingModal onSuccess={onSuccess} />
    </ErrorBoundary>
  );
};

export const MainLayout: FC<
  PropsWithChildren & { background?: PageProps['background'] }
> = ({ background = 'alt', children }) => {
  return (
    <Page background={background}>
      <PrimaryNav />
      {children}
    </Page>
  );
};

// https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/
const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(reactPages);

export const RouterProvider = () => <ReactRouterProvider router={router} />;
