import { useEffect, useState } from 'react';

export const useCCSDK = () => {
  const [chatLink, setChatLink] = useState<string | null>();
  const intervalMs = 100;

  useEffect(() => {
    const check = () => {
      const chatHolder =
        document.getElementsByClassName('woot-widget-holder')?.[0];
      const iframeUrl = chatHolder?.firstElementChild?.getAttribute('src');

      if (!iframeUrl) {
        timerId = setTimeout(check, intervalMs);
      } else {
        setChatLink(iframeUrl);
        clearInterval(timerId);
      }
    };

    let timerId = setTimeout(check, intervalMs);

    () => clearInterval(timerId);
  }, []);

  const startChat = () => {
    if (!chatLink) return;

    window.open(
      chatLink,
      '_blank',
      'width=600,height=400,right=0,bottom=0,toolbar=no,status=no,menubar=no,location=no'
    );
  };

  return {
    isCCSDKInitialized: Boolean(chatLink),
    startChat,
  };
};
