import { useNavigate } from 'react-router-dom';
import {
  useManagerCtxSelector,
  useCurrentState,
} from '../../xstate/app.xstate';
import { filterErrorTags, resetAppContext } from './helpers';
import { ErrorTags } from './types';

export const useResetAppContextAndGoToHome = () => {
  const navigate = useNavigate();
  const { routes } = useManagerCtxSelector((ctx) => ({ routes: ctx.routes }));
  return () => {
    resetAppContext();
    navigate(routes.home);
  };
};

export const useErrorTag = (tags?: ErrorTags | ErrorTags[]) => {
  const userTags = filterErrorTags(tags);
  const currentState = useCurrentState();
  const statesArr = Object.values(currentState);
  const isLocalError = statesArr.some((state) => state.hasTag(ErrorTags.local));
  const isGlobalError = statesArr.some((state) =>
    state.hasTag(ErrorTags.global)
  );
  const hasUserTags = !userTags.length
    ? false
    : statesArr.some((state) =>
        userTags.every((tag) => state.hasTag(tag as string))
      );

  return {
    isLocalError,
    isGlobalError,
    hasUserTags,
  };
};
