import {
  Button,
  Icon,
  Spacer,
  Stack,
  TextBody,
  TextTitle,
  TextCaption,
} from '@pypestream/design-system';
import { useController, useFormContext } from 'react-hook-form';

import {
  FormAppletToolingType,
  OutputAppletFormType,
  OutputProjectFormType,
} from '../../../../../components';
import { AppletToolField } from '../applet-modal';
import { GetAppletTemplatesQuery } from '@pypestream/api-services/dist/generated/graphql.urql';

export type AppletToolingPropsType = {
  nextStep: (formData?: Partial<OutputProjectFormType>) => void;
  prevStep: () => void;
  appletTemplates: NonNullable<GetAppletTemplatesQuery['admin_']>['applets'];
};

export const AppletTemplates = ({
  nextStep,
  prevStep,
  appletTemplates,
}: AppletToolingPropsType) => {
  const form = useFormContext();

  const { control, handleSubmit } = form;

  const { fieldState } = useController({
    name: 'appletVersionId',
    control,
    rules: { validate: (value) => !!value.length },
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <TextTitle size="small" textAlign="center">
        Select applet template to start with
      </TextTitle>
      <Spacer size="small" />
      <TextBody variant="secondary" textAlign="center">
        Applets templates help you to build applets with less configurations
      </TextBody>
      <Spacer size="2xlarge" />
      <Stack justifyContent="center" gutter="large">
        {appletTemplates?.map((applet) => {
          return (
            <AppletToolField
              key={String(applet.latestVersion?.id)}
              productId={String(applet.latestVersion?.id)}
              logo="analytics"
              label={applet.displayName || applet.pkgName}
              form={form}
            />
          );
        })}
      </Stack>
      {fieldState.invalid && (
        <>
          <Spacer size="xlarge" />
          <TextCaption variant="danger" textAlign="center">
            <Icon
              name="warning"
              size="xsmall"
              weight="regular"
              className="u-margin-right-xsmall"
            />
            Please select one applet
          </TextCaption>
        </>
      )}
      <Spacer size="xlarge" />
      <Stack justifyContent="space-between">
        <Button variant="ghost" size="large" type="button" onClick={prevStep}>
          Back
        </Button>
        <Button
          variant="primary"
          size="large"
          type="button"
          onClick={handleSubmit(nextStep)}
        >
          Next
        </Button>
      </Stack>
    </form>
  );
};
