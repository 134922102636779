import { AppletInstance } from '@pypestream/api-services';
import {
  Button,
  ButtonGroup,
  Icon,
  Menu,
  MenuItem,
  ModalProps,
  Spacer,
} from '@pypestream/design-system';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  sendManagerEvent,
  useGlobalAppCtxSelector,
} from '../../../../xstate/app.xstate';
import { AppletModal } from './applet-modal';
import { TranslationComponent } from '@pypestream/translations';

export const ReleaseChannelApplets = ({
  releaseChannelId,
  applets,
  accountId,
  projectId,
  disabled,
}: {
  releaseChannelId: string;
  applets: Array<AppletInstance>;
  accountId: string | undefined;
  projectId: string | undefined;
  disabled?: boolean;
}) => {
  const modalRef = useRef<ModalProps>(null);
  const navigate = useNavigate();
  const enableAppletPrototype = useGlobalAppCtxSelector(
    (ctx) => !!(ctx.featureFlags && ctx.featureFlags['applet-prototype'])
  );

  return (
    <>
      {releaseChannelId && (
        <AppletModal modalRef={modalRef} releaseChannelId={releaseChannelId} />
      )}
      <Spacer size="small" />

      {enableAppletPrototype && (
        <>
          <Menu>
            {applets?.map(({ id, name, projectReleaseChannelConfig }) => (
              <MenuItem key={id}>
                <ButtonGroup>
                  <Button
                    disabled={disabled}
                    variant="ghost"
                    size="large"
                    width="full"
                    onClick={() =>
                      navigate(
                        `/organization/${accountId}/projects/${projectId}/release-channels/${releaseChannelId}/applets/${id}`
                      )
                    }
                  >
                    {name || 'unnamed'}
                  </Button>
                  <Button
                    disabled={disabled}
                    variant="warning"
                    size="large"
                    onClick={() => {
                      sendManagerEvent({
                        type: 'manager.deleteApplet',
                        id,
                      });
                    }}
                  >
                    <Icon slot="prefix" name="delete" />
                  </Button>
                </ButtonGroup>
              </MenuItem>
            ))}
          </Menu>
          <Spacer size="small" />

          <Button
            disabled={disabled}
            variant="ghost"
            size="large"
            onClick={(e) => {
              if (modalRef.current) {
                modalRef.current.open = true;
              }
            }}
          >
            <TranslationComponent i18nKey="manager/projects:releaseChannels.addAppletButton">
              Add Applet
            </TranslationComponent>
            <Icon slot="prefix" name="add" />
          </Button>
        </>
      )}
    </>
  );
};
