import { Role } from '@pypestream/api-services';
import { useUserCtxSelector } from '../xstate/app.xstate';

export enum OrganizationRoleNames {
  SUPER_ADMIN = 'Super Admin',
  ADMIN = 'Organization Admin',
  MANAGER = 'Organization Manager',
}

export const useAuthRole = (authRoles: string[]) => {
  const { currentUser } = useUserCtxSelector((ctx) => ({
    currentUser: ctx.user,
  }));

  // Consolidate all roles (both team-based and directly assigned) in a single operation
  const allUserRoles = [
    // Roles assigned through teams
    ...(currentUser?.assignedTeams?.flatMap(({ assignedRoles }) =>
      assignedRoles?.map(({ id, name }) => ({ label: name, value: id }))
    ) || []),

    // Directly assigned roles
    ...(currentUser?.assignedRoles?.map(({ name, id }) => ({
      label: name,
      value: id,
    })) || []),
  ];

  return allUserRoles.some((role) => authRoles.includes(role?.label as string));
};
