import { Button } from '@pypestream/design-system';
import { TranslationKeys } from '@pypestream/translations';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalAppCtxSelector } from '../../xstate/app.xstate';

export const AppletGQLPlaygroundButton: FC<{
  channelId: string;
  i18nKey: TranslationKeys;
  accountId?: string;
  disabled?: boolean;
}> = ({ channelId, i18nKey, accountId, ...props }) => {
  const navigate = useNavigate();
  const enableAppletPrototype = useGlobalAppCtxSelector(
    (ctx) => (ctx.featureFlags && ctx.featureFlags['applet-prototype']) || false
  );

  if (!enableAppletPrototype) {
    return null;
  }
  return (
    <Button
      {...props}
      i18nKey={i18nKey}
      onClick={() => {
        navigate(`/organization/${accountId}/release-channels/${channelId}`);
      }}
    >
      GQL playground for Applets
    </Button>
  );
};
