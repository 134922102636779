import {
  Modal,
  ModalProps,
  StepperItem,
  Stepper,
  StepperWC,
} from '@pypestream/design-system';
import { useRef } from 'react';
import { createPortal } from 'react-dom';
import {
  useManagerCtxSelector,
  sendManagerEvent,
} from '../../xstate/app.xstate';
import {
  ProductCardsModal,
  ProjectFormFields,
  // FormProvider
} from '../../components';
import { ProjectInfo } from './create-project-modal/project-info';
import { ProjectTooling } from './create-project-modal/project-tooling';
import { ProjectLocalization } from './create-project-modal/project-localization';
import { ProjectCreated } from './create-project-modal/project-created';
import { ProjectReleaseChannels } from './create-project-modal/project-release-channels';
import { useProjectWizardForm } from './useProjectWizardForm';
import './homepage.scss';
import { FormProvider } from 'react-hook-form';

export type HomepageModalPropsType = {
  modalRef: React.RefObject<ModalProps> | null;
};

export const {
  ProjectNameField,
  ProjectDescriptionField,
  ProjectIconField,
  CountrySelect,
  TimeZoneSelect,
  LanguageSelect,
  ProjectReleaseChannelField,
  ProjectToolField,
} = new ProjectFormFields();

export const HomepageModal = ({ modalRef }: HomepageModalPropsType) => {
  const swiperRef = useRef<StepperWC>(null);

  const { products, selectedProjectId, selectedProject } =
    useManagerCtxSelector((ctx) => ({
      products: ctx.tools?.filter(({ productId }) => {
        return ctx.products?.find(({ id }) => id === productId);
      }),
      conditionalProducts: ctx.createProjectTools,
      selectedProjectId: ctx.selectedProjectModal,
      selectedProject: ctx.projects?.find(
        (p) => p?.projectId === ctx.selectedProjectModal
      ),
    }));

  const {
    resetFormState,
    createProject,
    projectInfoForm,
    projectReleaseChannelsForm,
    projectToolingForm,
    projectLocalizationForm,
    handleNextStep,
    handlePrevStep,
  } = useProjectWizardForm(swiperRef);

  const handleModalClose = () => {
    if (modalRef?.current) {
      modalRef.current.open = false;
      resetFormState();
    }
  };

  return (
    <>
      <ProductCardsModal
        project={selectedProject}
        open={!!selectedProjectId}
        onClose={() => {
          sendManagerEvent({
            type: 'manager.deselectProjectModal',
          });
        }}
      />
      {createPortal(
        <Modal
          ref={modalRef}
          usePortal={false}
          size="large"
          onClose={handleModalClose}
        >
          <Stepper id="stepper" ref={swiperRef}>
            <StepperItem>
              <FormProvider {...projectInfoForm}>
                <ProjectInfo nextStep={handleNextStep} />
              </FormProvider>
            </StepperItem>
            <StepperItem>
              <FormProvider {...projectReleaseChannelsForm}>
                <ProjectReleaseChannels
                  nextStep={handleNextStep}
                  prevStep={handlePrevStep}
                />
              </FormProvider>
            </StepperItem>
            <StepperItem>
              <FormProvider {...projectToolingForm}>
                <ProjectTooling
                  nextStep={handleNextStep}
                  prevStep={handlePrevStep}
                  products={products}
                />
              </FormProvider>
            </StepperItem>
            <StepperItem>
              <FormProvider {...projectLocalizationForm}>
                <ProjectLocalization
                  nextStep={createProject}
                  prevStep={handlePrevStep}
                />
              </FormProvider>
            </StepperItem>
            <StepperItem>
              <ProjectCreated closeModal={handleModalClose} />
            </StepperItem>
          </Stepper>
        </Modal>,
        document.body
      )}
    </>
  );
};
