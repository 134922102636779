import {
  Button,
  Spacer,
  Stack,
  TextBody,
  TextTitle,
} from '@pypestream/design-system';
import { useFormContext } from 'react-hook-form';

import { useManagerCtxSelector } from '../../../xstate/app.xstate';
import { OutputProjectFormType } from '../../../components';
import {
  ProjectDescriptionField,
  ProjectIconField,
  ProjectNameField,
} from '../homepage-modal';

export type ProjectInfoPropsType = {
  nextStep: (formData?: Partial<OutputProjectFormType>) => void;
};

export const ProjectInfo = ({ nextStep }: ProjectInfoPropsType) => {
  const { organizationId } = useManagerCtxSelector((ctx) => ({
    organizationId: ctx.selectedOrgId,
  }));

  const form = useFormContext();

  const { handleSubmit, register } = form;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <TextTitle size="small" textAlign="center">
        Create a project
      </TextTitle>
      <Spacer size="small"></Spacer>
      <TextBody variant="secondary" textAlign="center">
        Projects organize work around an online property like a website.
        Everything in the project must be tied to a domain and, optionally, its
        subdomains. You can make changes in project settings.
      </TextBody>
      <Spacer size="xlarge"></Spacer>
      <ProjectNameField _form={form} />
      <Spacer size="xlarge"></Spacer>
      <ProjectDescriptionField _form={form} {...register('description')} />
      <Spacer size="xlarge"></Spacer>
      <ProjectIconField
        form={form}
        uploadProps={{
          accountId: organizationId,
          label: 'Project Icon (optional)',
        }}
      />
      <Spacer size="xlarge"></Spacer>
      <Stack justifyContent="end">
        <Button
          variant="primary"
          size="large"
          type="button"
          onClick={handleSubmit(nextStep)}
        >
          Next
        </Button>
      </Stack>
    </form>
  );
};
