/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, PropsWithChildren } from 'react';
import { useCurrentState } from '../app.xstate';
import { isProduction } from '@pypestream/utils';

export const XstateDevLogger: FC<PropsWithChildren> = ({ children }) => {
  const currentState = useCurrentState();
  if (!isProduction) {
    console.info(
      '%ccurrent state: ',
      'color: violet; font-size: larger; font-weight: bold',
      currentState
    );
    // if you want to log sequence of events performed in the Manager machine
    // console.log(
    //   '%cmanagerHistoryValue: ',
    //   'color: violet; font-size: larger; font-weight: bold',
    //   JSON.stringify(
    //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //     // @ts-ignore
    //     currentState.manager.historyValue?.current?.orgRelated?.ready,
    //     null,
    //     2
    //   )
    // );
    // if you want to log current context of the User machine
    // console.log(
    //   '%cuserContext: ',
    //   'color: green; font-size: larger; font-weight: bold',
    //   // @ts-ignore
    //   JSON.stringify(currentState?.user?.context, null, 2)
    // );
  }
  return <>{children}</>;
};
