import {
  BlockList,
  Button,
  Spacer,
  Stack,
  TextBody,
  TextTitle,
} from '@pypestream/design-system';
import { useFormContext } from 'react-hook-form';

import { useManagerStateMatches } from '../../../xstate/app.xstate';
import { OutputProjectFormType } from '../../../components';
import {
  CountrySelect,
  TimeZoneSelect,
  LanguageSelect,
} from '../homepage-modal';

export type ProjectLocalizationPropsType = {
  nextStep: (formData?: Partial<OutputProjectFormType>) => void;
  prevStep: () => void;
};

export const ProjectLocalization = ({
  nextStep,
  prevStep,
}: ProjectLocalizationPropsType) => {
  const isAddingProject = useManagerStateMatches(
    'orgRelated.ready.projects.adding'
  );

  const form = useFormContext();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = form;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <TextTitle size="small" textAlign="center">
        Localization
      </TextTitle>
      <Spacer size="small"></Spacer>
      <TextBody variant="secondary" textAlign="center">
        These settings impact default localization preferences, timestamps in
        logs and metrics, and how to comply with local government regulations -
        including how and where user data is stored and what features are
        available.
      </TextBody>
      <Spacer size="xlarge"></Spacer>
      <BlockList gutter="xlarge">
        <CountrySelect
          {...register('countryId', { required: 'This is a required field' })}
          hoist={!window.Cypress}
          help-text={errors['countryId']?.message}
          hasError={!!errors['countryId']?.message}
          _form={form}
        />
        <TimeZoneSelect
          {...register('timeZoneId', { required: 'This is a required field' })}
          hoist={!window.Cypress}
          help-text={
            errors['timeZoneId']?.message ||
            'Applies to Analytics and Contact Center'
          }
          hasError={!!errors['timeZoneId']?.message}
          _form={form}
        />
        <LanguageSelect
          {...register('localeId', { required: 'This is a required field' })}
          hoist={!window.Cypress}
          help-text={
            errors['localeId']?.message || 'Applies only to Contact Center'
          }
          hasError={!!errors['localeId']?.message}
          _form={form}
        />
      </BlockList>
      <Spacer size="xlarge"></Spacer>
      <Stack justifyContent="space-between">
        <Button
          variant="ghost"
          size="large"
          type="button"
          onClick={prevStep}
          disabled={isAddingProject}
        >
          Back
        </Button>
        <Stack justifyContent="end">
          <Button
            variant="primary"
            size="large"
            type="button"
            onClick={handleSubmit(nextStep)}
            disabled={isAddingProject}
          >
            Next
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};
