import {
  Avatar,
  TableRow,
  TableRowWC,
  TableCell,
  Text,
  Stack,
  logos,
  IconButton,
  Menu,
  MenuItem,
  Textarea,
  TextareaWC,
  TextareaProps,
  Tooltip,
  Dropdown,
} from '@pypestream/design-system';
import { format, parseISO, formatDistanceToNowStrict } from 'date-fns';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { nanoid } from 'nanoid';
import {
  sendManagerEvent,
  useManagerCtxSelector,
} from '../../xstate/app.xstate';
import { ManagerContext, Project } from '../../xstate/manager.xstate';
import { useNavigate } from 'react-router-dom';
import {
  ToolsTranslationMapper,
  dateFnsLocales,
  fallbackLanguage,
  i18n,
} from '@pypestream/translations';

const InlineTextArea = ({
  disabled,
  initialValue,
  onBlur,
  onKeyDownCapture,
  style,
}: {
  disabled?: boolean;
  initialValue: TextareaProps['value'];
  onBlur: TextareaProps['onBlur'];
  onKeyDownCapture: TextareaProps['onKeyDownCapture'];
  style?: React.CSSProperties;
}) => {
  const projectNameRef = useRef<TextareaProps>(null);
  const [currentValue, setCurrentValue] = useState(initialValue);

  useEffect(() => {
    const elem = projectNameRef.current as TextareaWC;
    elem.value = currentValue || '';
  });

  useEffect(() => {
    const elem = projectNameRef.current as TextareaWC;
    if (elem.value) {
      elem.value = currentValue || '';
    }
  }, [currentValue]);

  return (
    <Textarea
      style={style}
      className="c-inline-text"
      disabled={disabled}
      initialValue={initialValue}
      resetOnEscape
      ref={projectNameRef}
      rows={1}
      placeholder="Project Title"
      onBlur={onBlur}
      onInputCapture={(event) => {
        setCurrentValue(event.target.value);
      }}
      onKeyDownCapture={onKeyDownCapture}
    />
  );
};

interface ProjectItemProps {
  item: NonNullable<ManagerContext['projects']>[number];
  onClick: () => void;
}

export const ProjectItem: FC<ProjectItemProps> = ({ item, onClick }) => {
  const {
    accountId,
    projectId,
    selectedProjectURL,
    conditionalProducts,
    hasAvailableProduct,
    pictureFile,
  } = item;

  const navigate = useNavigate();
  const picture = pictureFile?.url;

  const { userInfo, isEmployee } = useManagerCtxSelector((ctx) => ({
    userInfo: ctx.userInfo,
    isEmployee: ctx.userInfo.isPypestreamEmployee,
  }));

  const canManageAccess = userInfo?.canManageAccess;

  const buttonUUID = useRef(`icon-button-${nanoid()}`);

  const handleProjecrtItemClick = useCallback(
    (e: React.MouseEvent<TableRowWC>, _: Project) => {
      const element = e.target as HTMLElement;
      if (
        element.closest('ps-icon-button') ||
        element.tagName === 'PS-TEXTAREA'
      ) {
        return;
      }

      if (!selectedProjectURL) {
        onClick();

        return;
      }

      window.open(selectedProjectURL, '_self');
    },
    [onClick, selectedProjectURL]
  );

  const renderProjectItem = () => {
    const { t } = i18n;
    const translatedUpdatedAt = formatDistanceToNowStrict(
      parseISO(item?.updatedAt),
      {
        addSuffix: true,
        locale: dateFnsLocales[userInfo.defaultLanguage || fallbackLanguage],
      }
    );

    return (
      <TableRow
        key={projectId}
        {...(hasAvailableProduct
          ? {
              actionable: hasAvailableProduct,
              onClick: (e) => handleProjecrtItemClick(e, item),
            }
          : {
              style: {
                cursor: 'not-allowed',
              },
              title:
                t`manager/common:homepage.projects.YouDontHaveAccessToTheToolsInThisProject` ||
                '',
            })}
      >
        <TableCell nowrap>
          <Stack alignItems="center" nowrap>
            <Avatar
              size="xlarge"
              src={picture}
              label={`${item.name || 'Project Icon'}`}
              fallback="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ4IDQ4IiBmaWxsPSJub25lIj4KICAgICAgICAgICAgICAgICAgPHJlY3Qgd2lkdGg9IjQ4IiBoZWlnaHQ9IjQ4IiByeD0iMjQiIGZpbGw9IiNDREVCRUYiLz4KICAgICAgICAgICAgICAgICAgPG1hc2sgaWQ9InBhdGgtMi1pbnNpZGUtMV8zMTk2XzQ4MTUiIGZpbGw9IiNmZmYiPgogICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0yOS4zMzk3IDI0QzMzLjAxODEgMjQgMzYuMTM3IDIwLjgwMjYgMzQuMTk4NiAxNy42NzY0QzM0LjE4NDcgMTcuNjUzOSAzNC4xNzA3IDE3LjYzMTQgMzQuMTU2NSAxNy42MDlDMzIuOTUyMSAxNS42OTUgMzEuMjMxMyAxNC4xNjAyIDI5LjE5MjYgMTMuMTgxNkMyNy4xNTM4IDEyLjIwMyAyNC44Nzk5IDExLjgyMDUgMjIuNjMzMiAxMi4wNzgxQzIwLjM4NjQgMTIuMzM1NyAxOC4yNTgyIDEzLjIyMjkgMTYuNDkzOCAxNC42Mzc1QzE0LjcyOTMgMTYuMDUyMSAxMy40MDA2IDE3LjkzNjUgMTIuNjYwNiAyMC4wNzM1QzExLjkyMDYgMjIuMjEwNCAxMS43OTk1IDI0LjUxMyAxMi4zMTE0IDI2LjcxNThDMTIuODIzMiAyOC45MTg2IDEzLjk0NyAzMC45MzIgMTUuNTUzNCAzMi41MjM4QzE1LjU3MjIgMzIuNTQyNCAxNS41OTExIDMyLjU2MSAxNS42MSAzMi41Nzk1QzE4LjIzOTkgMzUuMTUxMyAyMi4wMzkgMzIuODAyMyAyMi44Mzg5IDI5LjIxMkMyMy41MTc0IDI2LjE2NjMgMjYuMjE5NCAyNCAyOS4zMzk3IDI0WiIvPgogICAgICAgICAgICAgICAgICA8L21hc2s+CiAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0yOS4zMzk3IDI0QzMzLjAxODEgMjQgMzYuMTM3IDIwLjgwMjYgMzQuMTk4NiAxNy42NzY0QzM0LjE4NDcgMTcuNjUzOSAzNC4xNzA3IDE3LjYzMTQgMzQuMTU2NSAxNy42MDlDMzIuOTUyMSAxNS42OTUgMzEuMjMxMyAxNC4xNjAyIDI5LjE5MjYgMTMuMTgxNkMyNy4xNTM4IDEyLjIwMyAyNC44Nzk5IDExLjgyMDUgMjIuNjMzMiAxMi4wNzgxQzIwLjM4NjQgMTIuMzM1NyAxOC4yNTgyIDEzLjIyMjkgMTYuNDkzOCAxNC42Mzc1QzE0LjcyOTMgMTYuMDUyMSAxMy40MDA2IDE3LjkzNjUgMTIuNjYwNiAyMC4wNzM1QzExLjkyMDYgMjIuMjEwNCAxMS43OTk1IDI0LjUxMyAxMi4zMTE0IDI2LjcxNThDMTIuODIzMiAyOC45MTg2IDEzLjk0NyAzMC45MzIgMTUuNTUzNCAzMi41MjM4QzE1LjU3MjIgMzIuNTQyNCAxNS41OTExIDMyLjU2MSAxNS42MSAzMi41Nzk1QzE4LjIzOTkgMzUuMTUxMyAyMi4wMzkgMzIuODAyMyAyMi44Mzg5IDI5LjIxMkMyMy41MTc0IDI2LjE2NjMgMjYuMjE5NCAyNCAyOS4zMzk3IDI0WiIgc3Ryb2tlPSIjNjhDNENFIiBzdHJva2VXaWR0aD0iMyIgbWFzaz0idXJsKCNwYXRoLTItaW5zaWRlLTFfMzE5Nl80ODE1KSIvPgogICAgICAgICAgICAgICAgPC9zdmc+"
            ></Avatar>
            <Text fontWeight="medium" size="xsmall" display="inline-flex">
              <InlineTextArea
                initialValue={item?.name}
                disabled={!canManageAccess || true}
                style={{
                  // eslint-disable-next-line no-constant-condition
                  pointerEvents: !canManageAccess || true ? 'none' : 'auto',
                }}
                onBlur={(e) => {
                  if (e.currentTarget.value === '') {
                    e.currentTarget.value = item?.name || 'default';
                  }

                  if (e.currentTarget.value !== item?.name) {
                    sendManagerEvent({
                      type: 'manager.updateProject',
                      name: e.currentTarget.value,
                      projectId,
                    });
                  }
                }}
                // onKeyDownCapture={(event) => {
                //   if (event.key === 'Enter' && event.shiftKey) {
                //     event.stopPropagation();
                //   }
                // }}
                onKeyDownCapture={(e) => {
                  if (e.key === 'Enter' && e.shiftKey) {
                    e.stopPropagation();
                  } else if (e.key === 'Enter') {
                    e.preventDefault();
                    e.currentTarget.blur();
                  } else if (e.key === 'Escape') {
                    // e.preventDefault();
                    // e.currentTarget.reset();
                    // e.currentTarget.blur();
                  }
                }}
              />
            </Text>
          </Stack>
        </TableCell>
        <TableCell nowrap>
          <Stack
            gutter="small"
            alignItems="center"
            justifyContent="start"
            nowrap
          >
            {conditionalProducts
              ?.filter((product) => product.integrated)
              .map((product) => {
                return (
                  <Tooltip
                    key={product.productId}
                    content={
                      product.disabled
                        ? t`manager/common:homepage.projects.YouDontHaveAccessToThisTool` ||
                          ''
                        : t(`${ToolsTranslationMapper[product.name]}`) || ''
                    }
                  >
                    <img
                      key={product.productId}
                      src={logos[product.logo]}
                      style={{
                        filter: product.disabled
                          ? 'grayscale(100%)'
                          : undefined,
                        height: '20px',
                        width: '20px',
                      }}
                      alt={t(`${ToolsTranslationMapper[product.name]}`) || ''}
                      title={t(`${ToolsTranslationMapper[product.name]}`) || ''}
                    />
                  </Tooltip>
                );
              })}
          </Stack>
        </TableCell>
        <TableCell nowrap>
          <Stack
            gutter="small"
            alignItems="center"
            justifyContent="start"
            nowrap
          >
            <Tooltip content={format(parseISO(item?.updatedAt), 'MM/dd/yyyy')}>
              <Text size="2xsmall">{translatedUpdatedAt}</Text>
            </Tooltip>
          </Stack>
        </TableCell>
        {canManageAccess && (
          <TableCell align="end">
            <IconButton name="meatballs" id={buttonUUID.current} />
            <Dropdown
              trigger={buttonUUID.current}
              placement="bottom-end"
              width="auto"
            >
              <Menu>
                <MenuItem
                  disabled={!accountId || !projectId || !isEmployee}
                  i18nKey="manager/common:homepage.projects.manageProject"
                  onClick={(e) => {
                    e.preventDefault();
                    sendManagerEvent({
                      type: 'manager.selectProject',
                      id: projectId,
                    });
                    navigate(
                      `/organization/${accountId}/projects/${projectId}`
                    );
                    window.scrollTo(0, 0);
                  }}
                >
                  Manage Project
                </MenuItem>
              </Menu>
            </Dropdown>
          </TableCell>
        )}
      </TableRow>
    );
  };

  return hasAvailableProduct ? (
    renderProjectItem()
  ) : (
    <Tooltip content="Contact your admin for access">
      {renderProjectItem()}
    </Tooltip>
  );
};
