import React from 'react';
import { Background, PageBody } from '@pypestream/design-system';

import { PageHeader, Loader, Sidebar } from '../../components';
import { HomepageContent } from './homepage-content';
import { useLoadingState } from '../../hooks';
import { IPage } from '../types';
import './homepage.scss';

export const Homepage: React.FC<IPage> = () => {
  const loading = useLoadingState();

  return (
    <>
      <PageHeader />
      <Background />
      <Sidebar />
      <PageBody background="none">
        {loading ? (
          <>
            <Loader relative />
          </>
        ) : (
          <HomepageContent />
        )}
      </PageBody>
    </>
  );
};
