import React, { FC } from 'react';
import {
  Card,
  Text,
  Divider,
  MediaObject,
  TextBody,
  TextOverline,
  Button,
  BlockList,
} from '@pypestream/design-system';

import { useCCSDK } from '../../hooks';
import EngineVideo from '../../assets/flow_bot_video.mp4';
import {
  TranslationComponent,
  TranslationKeys,
} from '@pypestream/translations';

const pypeLogo = (() => {
  const svgProps: React.SVGProps<SVGSVGElement> & { slot: string } = {
    slot: 'media',
    width: '40',
    height: '40',
    viewBox: '0 0 32 32',
    fill: 'none',
    xmlns: 'http://www.w3.org/2000/svg',
  };

  return (
    <svg {...svgProps} style={{ display: 'block', color: '#151a25' }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.31384 7.71082C5.2166 2.91652 10.4173 -0.00928526 16.0227 2.214e-05C22.9058 0.00976067 29.0112 4.42109 31.1823 10.9533C33.3533 17.4854 31.1034 24.6742 25.5958 28.8027C20.0881 32.9313 12.5573 33.0742 6.89697 29.1576C1.23663 25.241 -1.28441 18.1428 0.637266 11.533H16.3509C17.0924 11.5411 17.7915 11.7218 18.4106 12.0366C19.9093 12.818 20.9254 14.3917 20.9114 16.1954C20.9114 18.7704 18.8241 20.8579 16.2493 20.8579C13.6807 20.8429 11.6021 18.7642 11.5872 16.1954V15.1207H7.79263V16.1954C7.80736 20.8373 11.5493 24.6057 16.1907 24.6527H16.2571C20.9002 24.6401 24.6707 20.8972 24.7177 16.254V16.0977C24.7149 13.8982 23.8392 11.7898 22.2831 10.2355C20.7129 8.63255 18.5673 7.7236 16.3236 7.71082H2.31384Z"
        fill="currentColor"
      />
    </svg>
  );
})();

const infoSectionData: {
  icon: React.JSX.Element;
  title: string;
  label: string;
  link: string;
  titleTranslationKey: TranslationKeys;
  labelTranslationKey?: TranslationKeys;
}[] = [
  {
    icon: pypeLogo,
    title: 'Visit our site',
    label: 'Pypestream.com',
    link: 'https://pypestream.com',
    titleTranslationKey: 'manager/common:homepage.contacts.visitOurSite',
  },
  {
    icon: pypeLogo,
    title: 'See our blog',
    label: 'Blog',
    link: 'https://www.pypestream.com/blog',
    titleTranslationKey: 'manager/common:homepage.contacts.seeOurBlog',
    labelTranslationKey: 'manager/common:homepage.contacts.blog',
  },
  {
    icon: pypeLogo,
    title: 'Have questions?',
    label: 'Help Center',
    link: 'https://pypestream.atlassian.net/servicedesk/customer/portals',
    titleTranslationKey: 'manager/common:homepage.contacts.haveQuestions',
    labelTranslationKey: 'manager/common:homepage.contacts.helpCenter',
  },
];

export const SidebarWrapper: FC<{
  // url: string;
  // logo: keyof typeof logos;
  // name: string;
  children?: React.ReactNode;
}> = ({ children }) => (
  <Card
    className="c-homepage-sidebar js-sidebar"
    size="xlarge"
    display="flex"
    variant="glass"
    shadow="medium"
    radius="large"
    style={{
      gridArea: 'callout',
      paddingBottom: '32px',
      top: '100px',
      zIndex: 20,
    }}
  >
    {children}
  </Card>
);

export const Sidebar: FC<{
  // url: string;
  // logo: keyof typeof logos;
  // name: string;
}> = () => {
  // const accountManager = useManagerCtxSelector((ctx) => ctx.accountManager);
  const { isCCSDKInitialized, startChat } = useCCSDK();

  return (
    <SidebarWrapper>
      <Text
        size="small"
        textAlign="center"
        fontWeight="medium"
        className="u-margin-bottom-xlarge"
      >
        <TranslationComponent i18nKey="manager/common:homepage.contacts.needHelp">
          Need Help?
        </TranslationComponent>
      </Text>
      <div
        style={{
          width: 152,
          height: 152,
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 16,
        }}
      >
        <video
          width="152"
          height="152"
          autoPlay
          muted
          loop
          style={{ borderRadius: 200 }}
        >
          <source src={EngineVideo} type="video/mp4" />
          <TranslationComponent i18nKey="manager/common:homepage.contacts.yourBrowserDoesNotSupportVideoTag">
            Your browser does not support the video tag.
          </TranslationComponent>
        </video>
      </div>

      {/* {accountManager?.firstName && (
        <Text
          size="xsmall"
          textAlign="center"
          fontWeight="medium"
          className="u-margin-bottom-xsmall"
        >
          {accountManager?.firstName}
          {accountManager?.lastName ? ` ${accountManager?.lastName}` : ''}
        </Text>
      )} */}

      <Button
        variant="secondary"
        size="large"
        onClick={startChat}
        disabled={!isCCSDKInitialized}
        i18nKey="manager/common:homepage.contacts.startChat"
      >
        Start Chat
      </Button>

      {/* <Text size="2xsmall" textAlign="center" fontWeight="normal">
        930-292-3984
      </Text> */}

      {/* <Button
        className="u-margin-top-large"
        variant="secondary"
        size="large"
        disabled
      >
        Start Chat
      </Button> */}

      <Divider className="u-margin-top-2xlarge u-margin-bottom-2xlarge" />

      <Text
        size="2xsmall"
        textAlign="start"
        fontWeight="medium"
        className="u-margin-bottom-large"
        style={{
          alignSelf: 'start',
        }}
      >
        <TranslationComponent i18nKey="manager/common:homepage.contacts.learnMore">
          Learn More...
        </TranslationComponent>
      </Text>

      <BlockList gutter="xlarge">
        {infoSectionData.map(
          ({
            title,
            link,
            label,
            icon,
            titleTranslationKey,
            labelTranslationKey,
          }) => (
            <MediaObject href={link} target="_blank" key={link}>
              {icon}
              <TextOverline truncate>
                <TranslationComponent i18nKey={titleTranslationKey}>
                  {title}
                </TranslationComponent>
              </TextOverline>
              <TextBody size="small">
                {labelTranslationKey ? (
                  <TranslationComponent
                    i18nKey={labelTranslationKey}
                  ></TranslationComponent>
                ) : (
                  label
                )}
              </TextBody>
            </MediaObject>
          )
        )}
      </BlockList>
    </SidebarWrapper>
  );
};
