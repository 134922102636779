/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Page,
  PageBody,
  Result,
  Stack,
} from '@pypestream/design-system';
import { FC, useEffect } from 'react';
import {
  ErrorBoundary as ReactErrorBoundary,
  useErrorBoundary,
} from 'react-error-boundary';
import { useManagerCtxSelector } from '../../xstate/app.xstate';
import { formatErrorMessage, logError } from './helpers';
import { useErrorTag, useResetAppContextAndGoToHome } from './hooks';
import { ErrorPageProps, ChildrenProps } from './types';

export const FormattedErrorMessage: FC<{ message: string }> = ({ message }) => {
  const { msg, jsonMsg, query } = formatErrorMessage(message);

  const jsonMessage = jsonMsg ? (
    <>
      <br />
      <br />
      {jsonMsg}
    </>
  ) : null;

  const gqlQuery = query ? (
    <>
      <br />
      <br />
      {query}
    </>
  ) : null;

  return (
    <pre
      style={{
        color: 'white',
        padding: '20px',
        background: 'black',
        whiteSpace: 'pre-wrap',
      }}
    >
      {msg}
      {jsonMessage}
      {gqlQuery}
    </pre>
  );
};

const ErrorPage: FC<ErrorPageProps> = ({ error, resetErrorBoundary }) => {
  return (
    <Page background="secondary">
      <PageBody background="none">
        <Result
          align="center"
          status="error"
          label={error.message}
          body="Page not found."
        >
          <Stack gutter="xsmall" justifyContent="center" slot="footer">
            <Button
              size="large"
              variant="secondary"
              onClick={resetErrorBoundary}
            >
              Reload Page
            </Button>
            <Button
              size="large"
              onClick={() => (document.location.pathname = '/')}
            >
              Go Home
            </Button>
          </Stack>
        </Result>
      </PageBody>
    </Page>
  );
};

const UncaughtErrorBoundary: FC<ChildrenProps> = ({ children }) => {
  const { isLocalError } = useErrorTag();
  const managerMachineErrors = useManagerCtxSelector((ctx) => ctx.errors);
  const errors = !isLocalError ? managerMachineErrors : null;
  const { showBoundary } = useErrorBoundary();

  const handler = (e: ErrorEvent) => showBoundary(e);

  const removeErrorListener = () => {
    if (typeof window === 'undefined') {
      return;
    }

    window.removeEventListener('error', handler);
  };

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    if (isLocalError) {
      removeErrorListener();

      return;
    }

    window.addEventListener('error', handler);

    return () => {
      removeErrorListener();
    };
  }, [isLocalError]);

  useEffect(() => {
    if (!errors || errors.length === 0) {
      return;
    }

    showBoundary(errors);
  }, [errors]);

  return <>{children}</>;
};

export const ErrorBoundary: FC<ChildrenProps> = ({ children }) => {
  const resetApp = useResetAppContextAndGoToHome();

  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorPage}
      onError={(e, i) => logError(e, i)}
      onReset={resetApp}
    >
      <UncaughtErrorBoundary>{children}</UncaughtErrorBoundary>
    </ReactErrorBoundary>
  );
};
