/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActivityConfig, PayloadSender, State, Typestate } from 'xstate';
import type { ToastProps } from '@pypestream/design-system';
import { LDFlagValue } from 'launchdarkly-js-client-sdk';

import {
  AvailableFeatureFlags,
  FeatureFlagType,
} from '../feature-flags/feature-flags.types';
import { getXstateUtils, MachineStateSchemaPaths } from './xstate.utils';
import { UserContext } from './user.xstate';

export interface UserInfo {
  displayName?: string;
  firstName?: string;
  lastName?: string;
  profilePhoto?: string;
}

/**
 * These State Machine IDs are invoked from within the App machine
 * Most are invoked right when the App machine starts and are global always-on machines. These are the the sub-machines that get custom React hooks made and exported out.
 * Others are invoked in certain states.
 * These IDs are used for sending events directly to them.
 */
export enum GLOBAL_APP_SUB_MACHINE_IDS {
  ui = 'ui',
  user = 'user',
  manager = 'manager',
}

type Account = {
  id: string;
  apps: string[];
};

type App = {
  id: string;
  name: string;
  versions?: string[];
  latestVersion?: string;
  canPublish?: boolean;
  activeVersions?: string[];
  inactiveVersions?: string[];
};

export interface AccountLoadResults {
  accounts: Account[];
}

export interface AppLoadResults {
  apps: App[];
}

export interface GlobalAppContext {
  accounts?: AccountLoadResults;
  apps?: AppLoadResults;
  featureFlags?: FeatureFlagType;
}

/* eslint-disable @typescript-eslint/ban-types */
export interface GlobalAppStateSchema {
  states: {
    featureFlags: {
      states: {
        idle: Record<string, unknown>;
        loaded: {
          states: {
            withActualValue: Record<string, unknown>;
            // With error state, we will initialized all featureFlags with default compile time values.
            withDefaultValues: Record<string, unknown>;
          };
        };
      };
    };
  };
}

export type SharedEvents =
  // Sometimes we need to return an event that does nothing
  | { type: 'noop' }
  //   }
  | { type: 'user.signOut' }
  | {
      type: 'user.signIn';
      user: UserContext['user'];
    }
  | {
      type: 'user.assignedUserId';
      userId: NonNullable<UserContext['user']>['id'];
    }
  | {
      type: 'reset.errors';
    }
  | { type: 'user.loggedIn' }
  | { type: 'user.setProfilePhoto'; profilePhoto: string | undefined }
  | { type: 'user.authError'; errorMsg: string }
  | {
      type: 'featureFlags.initialized';
      flags: FeatureFlagType;
    }
  | { type: 'featureFlags.initializedWithDefaultValues' }
  | {
      type: 'featureFlags.update';
      updatedFlag: AvailableFeatureFlags;
      updatedValue: LDFlagValue;
    };

export type GlobalAppEvents =
  | { type: 'app.log'; msg: any }
  | { type: 'app.sendUserMessage'; msg: ToastProps }
  | { type: 'navigate.log'; msg: any }
  | SharedEvents;

/** All `AppEvents` whose `type` starts with `user` followed by other strings */
export type GlobalAppUserEvents = Extract<
  GlobalAppEvents,
  { type: `user.${string}` }
>;

/**
 * All state values as strings in dot notation, i.e. `'user.loggedIn' | 'user.loggedIn.loading'`
 * These strings are passed to `state.matches('user.loggedIn')` which returns a `boolean`
 * If `state.matches()` is not typed correctly, use this type:
 * @example
 * const isLoggedIn = state.matches<AppStateValues>('user.loggedIn');
 */
export type GlobalAppStateValues = MachineStateSchemaPaths<
  GlobalAppStateSchema['states']
>;
export interface GlobalAppTypestates extends Typestate<GlobalAppContext> {
  context: GlobalAppContext;
  value: GlobalAppStateValues;
}

export type GlobalAppState = State<
  GlobalAppContext,
  GlobalAppEvents,
  GlobalAppStateSchema,
  GlobalAppTypestates
>;

export type GlobalAppSender = PayloadSender<GlobalAppEvents>;

export const {
  createAction,
  createInvokablePromise,
  createXstateHooks: createAppXstateHooks,
} = getXstateUtils<
  GlobalAppContext,
  GlobalAppEvents,
  GlobalAppTypestates,
  GlobalAppStateSchema
>();

export type Activity = ActivityConfig<GlobalAppContext, GlobalAppEvents>;

const exampleActivity: Activity = (ctx, activity): ReturnType<Activity> => {
  // Start the beeping activity
  const interval = setInterval(() => {
    // console.log('BEEP!');
  }, 2000);

  // Return a function that stops the beeping activity
  return () => clearInterval(interval);
};

export const appActivities: Record<
  'exampleActivity',
  { id: string; exec: Activity }
> = {
  exampleActivity: {
    id: 'exampleActivity',
    exec: exampleActivity,
  },
};

export function sendUserMessage(msg: ToastProps): void {
  import('@pypestream/design-system').then(({ sendToast }) => sendToast(msg));
}

export const globalAppActions = {
  log: createAction<{
    level?: 'warn' | 'error' | 'debug';
    msg: string;
    details?: Record<string, unknown>;
  }>({
    id: 'log',
    exec({ action, event, ctx, state }) {
      const { level = 'log', msg, details } = action;
      console[level](`${msg}. Event: ${event.type}`, {
        event,
        ctx,
        details,
        state,
      });
    },
  }),
  logError: createAction({
    id: 'logError',
    exec({ event }) {
      const { data: error } = event as { type: string; data: Error };
      if (error instanceof Error) {
        console.error(error);
      } else {
        console.error(event);
      }
    },
  }),
};
