import {
  Modal,
  ModalProps,
  StepperItem,
  Stepper,
  StepperWC,
} from '@pypestream/design-system';
import { useRef } from 'react';
import { createPortal } from 'react-dom';
import { useManagerCtxSelector } from '../../../../xstate/app.xstate';
import {
  AppletFormFields,
  // FormProvider
} from '../../../../components';
import { AppletInfo } from './create-applet-modal/applet-info';
import { AppletTemplates } from './create-applet-modal/applet-templates';
import { AppletCreated } from './create-applet-modal/applet-created';
import { useAppletWizardForm } from './useAppletWizardForm';
import { FormProvider } from 'react-hook-form';

export type HomepageModalPropsType = {
  modalRef: React.RefObject<ModalProps> | null;
  releaseChannelId: string;
};

export const { AppletNameField, AppletDescriptionField, AppletToolField } =
  new AppletFormFields();

export const AppletModal = ({
  modalRef,
  releaseChannelId,
}: HomepageModalPropsType) => {
  const swiperRef = useRef<StepperWC>(null);

  const { appletTemplates, selectedProjectId, selectedProject } =
    useManagerCtxSelector((ctx) => ({
      appletTemplates: ctx.appletTemplates,
      conditionalProducts: ctx.createProjectTools,
      selectedProjectId: ctx.selectedProjectModal,
      selectedProject: ctx.projects?.find(
        (p) => p?.projectId === ctx.selectedProjectModal
      ),
    }));

  const {
    resetFormState,
    createAppletInstance,
    appletInstanceInfoForm,
    appletInstanceToolingForm,
    handleNextStep,
    handlePrevStep,
  } = useAppletWizardForm(swiperRef, releaseChannelId);

  const handleModalClose = () => {
    if (modalRef?.current) {
      modalRef.current.open = false;
      resetFormState();
    }
  };

  return (
    <>
      {createPortal(
        <Modal
          ref={modalRef}
          usePortal={false}
          size="large"
          onClose={handleModalClose}
        >
          <Stepper id="stepper" ref={swiperRef}>
            <StepperItem>
              <FormProvider {...appletInstanceInfoForm}>
                <AppletInfo nextStep={handleNextStep} />
              </FormProvider>
            </StepperItem>
            <StepperItem>
              <FormProvider {...appletInstanceToolingForm}>
                <AppletTemplates
                  nextStep={createAppletInstance}
                  prevStep={handlePrevStep}
                  appletTemplates={appletTemplates}
                />
              </FormProvider>
            </StepperItem>
            <StepperItem>
              <AppletCreated closeModal={handleModalClose} />
            </StepperItem>
          </Stepper>
        </Modal>,
        document.body
      )}
    </>
  );
};
