import {
  Button,
  Spacer,
  Stack,
  TextBody,
  TextTitle,
} from '@pypestream/design-system';
import { useFormContext } from 'react-hook-form';

import { useManagerCtxSelector } from '../../../../../xstate/app.xstate';
import { OutputProjectFormType } from '../../../../../components';
import { AppletDescriptionField, AppletNameField } from '../applet-modal';

export type ProjectInfoPropsType = {
  nextStep: (formData?: Partial<OutputProjectFormType>) => void;
};

export const AppletInfo = ({ nextStep }: ProjectInfoPropsType) => {
  const { organizationId } = useManagerCtxSelector((ctx) => ({
    organizationId: ctx.selectedOrgId,
  }));

  const form = useFormContext();

  const { handleSubmit, register, control } = form;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <TextTitle size="small" textAlign="center">
        Create an applet
      </TextTitle>
      <Spacer size="small"></Spacer>
      <TextBody variant="secondary" textAlign="center">
        The Pypestream Applet is the runtime environment for Pypestream platform
        functionality on a given website. The App Loader code snippet calls the
        Pypestream platform and retrieves and executes any additional code
        needed on the page assuming certain conditions are met. This includes
        Analytics tracking code, chat widget code, and Elements from the
        automation engine.
      </TextBody>
      <Spacer size="xlarge"></Spacer>
      <AppletNameField control={control} />
      <Spacer size="xlarge"></Spacer>
      <AppletDescriptionField {...register('description')} />
      <Spacer size="xlarge"></Spacer>
      <Stack justifyContent="end">
        <Button
          variant="primary"
          size="large"
          type="button"
          onClick={handleSubmit(nextStep)}
        >
          Next
        </Button>
      </Stack>
    </form>
  );
};
