import { CombinedError } from '@pypestream/api-services/urql';

export type UrqlGqlErrors = {
  errors: CombinedError[];
};

export const haveErrors = <T>(
  value: T | UrqlGqlErrors | undefined
): value is UrqlGqlErrors => {
  return (value as UrqlGqlErrors)?.errors?.length > 0;
};
