import { DefaultFeatureFlags } from '../feature-flags/feature-flags.default';
import { AvailableFeatureFlags } from '../feature-flags/feature-flags.types';
import { globalAppService } from '../xstate/app.xstate';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isValidFlag = (flagKey: any): boolean => {
  const isValid = flagKey in DefaultFeatureFlags;

  if (!isValid) {
    // @todo - Need to log this with datadog once available.
    // Also, do we need to log app version too?
    console.log('Unhandled feature flag :- ', flagKey);
  }
  return isValid;
};

export const getFlagValue = (flagKey: AvailableFeatureFlags) => {
  if (!globalAppService.initialized) {
    return null;
  }

  const featureFlagSnapshot =
    globalAppService.getSnapshot().context.featureFlags;
  if (featureFlagSnapshot && Object.keys(featureFlagSnapshot).length) {
    return featureFlagSnapshot[flagKey];
  }

  return null;
};
