import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { isProduction } from '@pypestream/utils';

import packageJson from '../package.json';

export const datadogInit = () => {
  const commonInitConfig = {
    clientToken: import.meta.env.FE_DATADOG_CLIENT_TOKEN,
    site: import.meta.env.FE_DATADOG_SITE,
    service: import.meta.env.FE_DATADOG_MANAGER_SERVICE,
    env: isProduction ? 'production' : 'development',
    version: packageJson.version,
    sessionSampleRate: 100,
  };

  const rumInitConfig: RumInitConfiguration = {
    ...commonInitConfig,
    applicationId: import.meta.env.FE_DATADOG_APP_ID,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  };

  const isRumInitConfigCorrect = Object.values(rumInitConfig).every(
    (env) => !!env
  );

  if (!isRumInitConfigCorrect) {
    console.log(
      '%cDataDog: service initialization failed, please check your .env file',
      'color: red; font-size: larger; font-weight: bold'
    );
    return;
  }

  datadogRum.init(rumInitConfig);
  datadogLogs.init({
    ...commonInitConfig,
    forwardErrorsToLogs: true,
  });

  const context = datadogRum.getInternalContext();

  if (context?.application_id) {
    console.log(
      '%cDataDog: service initialized successfully',
      'color: green; font-size: larger; font-weight: bold',
      isProduction ? '' : context
    );
    datadogLogs.logger.info(
      `[Datadog] Service initialized successfully ${new Date().toISOString()}`
    );
  } else {
    console.log(
      '%cDataDog: service initialization failed. Internal context is not available',
      'color: red; font-size: larger; font-weight: bold'
    );
  }
};
