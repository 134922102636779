import { FC } from 'react';
import { formatDistance, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import {
  PageBody,
  TextTitle,
  TextCaption,
  Spacer,
  PageSidenav,
  Menu,
  MenuItem,
  Button,
  TextSubtitle,
  Icon,
} from '@pypestream/design-system';
import {
  dateFnsLocales,
  fallbackLanguage,
  TranslationComponent,
  TranslationKeys,
  useTranslation,
} from '@pypestream/translations';

import { useManagerCtxSelector } from '../../../xstate/app.xstate';
import { IPage } from '../../types';
import { RenderProjectDetailsContent } from './tabs';

export enum ProjectDetailsTabs {
  general = '',
  releaseChannels = 'release-channels',
}

const tabs: {
  label: string;
  value: ProjectDetailsTabs;
  translationKey: TranslationKeys;
}[] = [
  {
    label: 'General',
    value: ProjectDetailsTabs.general,
    translationKey: 'manager/projects:sidebar.general',
  },
  {
    label: 'Release Channels',
    value: ProjectDetailsTabs.releaseChannels,
    translationKey: 'manager/projects:sidebar.releaseChannels',
  },
];

export const ProjectDetails: FC<IPage> = ({
  title,
  tab,
  hasSidebar,
  hideDeleteButton,
}) => {
  const navigate = useNavigate();

  const { routes, project } = useManagerCtxSelector((ctx) => {
    return {
      project: ctx.projects?.find(
        ({ projectId }) => projectId === ctx.selectedProject
      ),
      routes: ctx.routes,
    };
  });

  const [t] = useTranslation();

  const projectName =
    project?.name ||
    title ||
    t('manager/projects:sidebar.projectDetails', {
      defaultValue: 'Project Details',
    });

  return (
    <>
      {hasSidebar && (
        <PageSidenav>
          <Button
            size="small"
            variant="ghost"
            onClick={() => navigate(routes.projects)}
            i18nKey="manager/projects:sidebar.backToProjects"
          >
            <Icon name="chevron-left" />
            All Projects
          </Button>
          <Spacer size="large" />
          <TextSubtitle size="small">{projectName}</TextSubtitle>
          <Spacer size="medium" />
          <Menu>
            {tabs.map(({ value, label, translationKey }) => (
              <MenuItem
                key={value}
                value={value}
                selected={value === tab}
                onClick={() => {
                  navigate(`${routes.projects}/${project?.projectId}/${value}`);
                }}
              >
                <TranslationComponent i18nKey={translationKey}>
                  {label}
                </TranslationComponent>
              </MenuItem>
            ))}
          </Menu>
        </PageSidenav>
      )}
      <PageBody background="none">
        <RenderProjectDetailsContent
          project={project}
          tab={tab}
          hideDeleteButton={hideDeleteButton}
        />
      </PageBody>
    </>
  );
};
