import { PageProps } from '@pypestream/design-system';
import { ConsentStatus } from '@pypestream/api-services';
import { usePostHog } from 'posthog-js/react';
import { FC, PropsWithChildren, Suspense, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import {
  useManagerCtxSelector,
  useManagerStateMatches,
  useUserCtxSelector,
} from '../xstate/app.xstate';
import { syncXstateWithRouteParams } from '../utils';
import { Root, MainLayout } from '../router';

const RouteDataProvider: FC<PropsWithChildren> = ({ children }) => {
  const posthog = usePostHog();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const params = useParams();
  const { org_id: orgIdQueryParam } = params;
  const { orgId } = useManagerCtxSelector((ctx) => ({
    orgId: ctx.selectedOrgId,
  }));
  const areOrgsLoaded = useManagerStateMatches('orgRelated.ready.orgs.loaded');

  const { optionalConsentStatus } = useUserCtxSelector((ctx) => ({
    optionalConsentStatus: ctx.user?.optionalConsentStatus,
  }));

  useEffect(() => {
    if (!areOrgsLoaded) {
      return;
    }

    syncXstateWithRouteParams({ params });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, areOrgsLoaded]);

  useEffect(() => {
    if (optionalConsentStatus === ConsentStatus.Accepted) {
      posthog.capture('$pageview');
    }

    if (orgId && !orgIdQueryParam) {
      navigate(`/organization/${orgId}${pathname}`, { replace: true });
    }
  }, [
    pathname,
    orgId,
    optionalConsentStatus,
    posthog,
    orgIdQueryParam,
    navigate,
  ]);

  return <>{children}</>;
};

export function WithRouteData<T extends Record<string, unknown>>(
  WrappedComponent: React.ComponentType<T>
) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithRouteData = (props: T) => {
    return (
      <RouteDataProvider>
        <Root>
          <MainLayout background={props?.background as PageProps['background']}>
            <WrappedComponent {...props} />
          </MainLayout>
        </Root>
      </RouteDataProvider>
    );
  };

  ComponentWithRouteData.displayName = `WithRouteData(${displayName})`;

  return ComponentWithRouteData;
}
