import {
  Button,
  Image,
  logos,
  Spacer,
  Stack,
  TextTitle,
} from '@pypestream/design-system';

export type ProjectCreatedPropsType = {
  closeModal: () => void;
};

export const AppletCreated = ({ closeModal }: ProjectCreatedPropsType) => {
  return (
    <>
      <Stack justifyContent="center">
        <Image src={logos.project}></Image>
      </Stack>
      <Spacer size="xlarge"></Spacer>
      <TextTitle size="small" textAlign="center">
        Success!
      </TextTitle>
      <TextTitle size="small" textAlign="center">
        New applet has been created...
      </TextTitle>
      <Spacer size="xlarge"></Spacer>
      <Stack justifyContent="end">
        <Button
          variant="primary"
          size="large"
          type="button"
          onClick={closeModal}
        >
          Done
        </Button>
      </Stack>
    </>
  );
};
